<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <div>
          <el-form inline>
            <el-form-item>
              <el-button icon='el-icon-plus' v-if="userPermissions.indexOf('platform_create') > -1" type='primary'
                         @click='addButton(0)'>
                新增
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button :disabled='loadingStaus' :loading='loadingStaus' type='primary' icon='el-icon-refresh'
                         @click='handleQuery'>刷新
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <TreeTable :data='dataList' :columns='columns' :loading='loadingStaus' ref='treeTable'
                   highlight-current-row>
          <el-table-column
            slot='first-column'
            width='80'
            align='center'
            label='Drag'>
            <template slot-scope='scope'>
              <el-tooltip effect='dark' content='拖动排序' placement='top-start'>
                <span class='drag-handle' :data-id='scope.row.id' :data-parent_id='scope.row.parent_id'
                      :data-depth='scope.row.depth'><i class='el-icon-rank'></i></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label='数据录入' prop='is_data_entry' :formatter='statusFormat'></el-table-column>
          <el-table-column label='刊例录入' prop='is_plc_entry' :formatter='statusFormat'></el-table-column>
          <el-table-column label='资源库' prop='is_kol_entry' :formatter='statusFormat'></el-table-column>

          <el-table-column
            label='操作' min-width='160' align='center'>
            <template slot-scope='scope'>
              <div class='col-opt'>
                <el-link type='warning' size='mini' @click='showPlcPage(scope.row)'
                         v-if="userPermissions.indexOf('platform_setting')>-1" icon='el-icon-setting'>刊例
                </el-link>

                <el-tooltip effect='dark' content='编辑' placement='top-start'
                            v-if="userPermissions.indexOf('platform_edit') > -1">
                  <el-link type='primary' size='mini' icon='el-icon-edit' @click='editButton(scope.row.id)'>修改</el-link>
                </el-tooltip>
                &nbsp;&nbsp;
                <el-tooltip effect='dark' content='删除' placement='top-start'>
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('platform_delete') > -1"
                    placement='top'
                    width='150'
                    v-model='scope.row.visible'>
                    <p>确定要删除记录吗？</p>
                    <div style='text-align: right; margin: 0;'>
                      <el-button type='text' size='mini' @click='scope.row.visible=false'>取消</el-button>
                      <el-button type='danger' size='mini' @click='deleteButton(scope.row.id)'>确定</el-button>
                    </div>
                    <el-link slot='reference' type='danger' size='mini' icon='el-icon-delete'>删除</el-link>
                  </el-popover>
                </span>
                </el-tooltip>
                &nbsp;&nbsp;
              </div>
            </template>
          </el-table-column>
        </TreeTable>
      </div>
    </PageHeaderLayout>

    <ModalDialog :dialogData='dialogData' @dialogConfirm='handleConfirm' @dialogClose='dialogClose'>
      <template slot='content'>
        <el-form :model='formData' :rules='rules' ref='categoryForm' label-position='right' label-width='120px'>
          <el-form-item label='平台名称' prop='name'>
            <el-input v-model='formData.name'></el-input>
          </el-form-item>
          <el-form-item label='平台标识' prop='code'>
            <el-input v-model='formData.code'></el-input>
          </el-form-item>
          <el-form-item label='统一标识' prop='uq_code'>
            <el-input v-model='formData.uq_code' placeholder='统一标识用于系统（星广汇）之间数据匹配'></el-input>
          </el-form-item>
          <el-form-item label='平台官网' prop='url'>
            <el-input v-model='formData.website'></el-input>
          </el-form-item>
          <!--          <el-form-item label="备注" prop="description">-->
          <!--            <el-input type="textarea" v-model="formData.description"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label='Logo图片' prop='image' ref='imageItem'>
            <ApeUploader :limit='1' @handleUploadSuccess='handleUploadSuccess' @handleUploadRemove='handleUploadRemove'
                         :upload-file-list='uploadFileList'></ApeUploader>
          </el-form-item>
          <el-form-item label='数据录入'>
            <el-radio-group v-model='formData.is_data_entry'>
              <el-radio
                v-for='dict in statusOptions'
                :key='dict.dictValue'
                :label='dict.dictValue'
              >{{ dict.dictLabel }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='刊例录入'>
            <el-radio-group v-model='formData.is_plc_entry'>
              <el-radio
                v-for='dict in statusOptions'
                :key='dict.dictValue'
                :label='dict.dictValue'
              >{{ dict.dictLabel }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='红人资源库'>
            <el-radio-group v-model='formData.is_kol_entry'>
              <el-radio
                v-for='dict in statusOptions'
                :key='dict.dictValue'
                :label='dict.dictValue'
              >{{ dict.dictLabel }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import TreeTable from '@/components/TreeTable'
import Sortable from 'sortablejs'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader'


export default {
  name: 'PlatformList',
  components: {
    PageHeaderLayout,
    TreeTable,
    ModalDialog,
    ApeUploader
  },
  data() {
    return {
      loadingStaus: true,
      selectedList: [],
      dialogData: {
        visible: false,
        title: '',
        width: '40%',
        loading: true,
        modal: false
      },
      columns: [
        {
          title: 'ID',
          value: 'id',
          width: 60
        },
        {
          title: '图片',
          type: 'image',
          value: 'logo_url',
          width: 100
        },
        {
          title: '平台标识',
          value: 'code',
          width: 100
        }, {
          title: '统一标识',
          value: 'uq_code',
          width: 100
        },
        {
          title: '平台名称',
          operation: true, // 作为展开操作列
          value: 'name',
          width: 140
        },
        {
          title: '官网',
          value: 'website'
          // width: 200
        },
        {
          title: '排序',
          value: 'order',
          width: 80
        }
        // {
        //   title: '用于数据录入',
        //   value: 'is_data_entry',
        //   width: 100
        // }, {
        //   title: '用于刊例录入',
        //   value: 'is_plc_entry',
        //   width: 100
        // }

      ],
      // 表格列表数据
      dataList: [],
      defaultFormData: {
        display_name: '',
        parent_id: 0
      },
      // 表单结构
      formData: { name: '', code: '', uq_code: '', website: '', is_data_entry: 1, is_plc_entry: 1, is_kol_entry: 1 },
      // 已上传文件列表
      uploadFileList: [],
      // 表单验证
      rules: {
        code: [{ required: true, message: '输入平台标识', trigger: 'blur' }],
        name: [{ required: true, message: '输入平台名称', trigger: 'blur' }]
      },
      // 类型数据字典
      statusOptions: [
        { dictValue: 1, dictLabel: '是' },
        { dictValue: 0, dictLabel: '否' }
      ]
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    async handleQuery() {
      this.loadingStaus = true
      let { list } = await this.$api.getPlatformList({})
      this.dataList = list || []
      this.loadingStaus = false
    },
    statusFormat(row, column) {
      let val = this.statusOptions.find(value => value.dictValue === row[column.property])
      return val.dictLabel || '-'
    },
    // 响应添加按钮
    async addButton() {
      this.dialogData.visible = true
      this.dialogData.title = '添加平台'
      // this.formData.parent_id = pid
      this.dialogData.loading = false
      // this.formData.is_data_entry = 1
      // this.formData.is_plc_entery = 1
      this.formData = {
        name: '',
        code: '',
        uq_code: '',
        website: '',
        is_data_entry: 1,
        is_plc_entry: 1,
        is_kol_entry: 1
      }
    },
    // 响应编辑按钮
    async editButton(id) {
      this.dialogData.visible = true
      this.dialogData.title = '编辑分类'
      let { info } = await this.$api.getPlatformInfo(id)
      // if (info.parent_id) {
      //   this.rules.name[0].required = false
      // } else {
      //   this.rules.name[0].required = true
      // }
      this.formData = { ...info }
      this.uploadFileList = info.id ? [{ name: info.name, url: info.logo_url }] : []
      this.dialogData.loading = false
    },
    // 相应删除按钮
    async deleteButton(id) {
      let ids = this.$refs['treeTable'].getExpandIds()
      let info = await this.$api.deletePlatformInfo(id)
      if (info == 'ok') {
        await this.handleQuery()
        this.$nextTick(() => {
          this.$refs['treeTable'].initTableExpand(ids)
        })
        this.msgSuccess('删除成功!')
        // this.$message.success('删除成功!')
      } else {
        this.$message.error(info)
      }
    },
    //添加,保存
    async formSubmit() {
      let ids = this.$refs['treeTable'].getExpandIds()
      let id = await this.$api.savePlatform(this.formData)
      if (id) {

        this.handleQuery()
      }
      this.initData()
      this.$nextTick(() => {
        this.$refs['treeTable'].initTableExpand(ids)
      })
      this.msgSuccess('保存成功!')
      // this.$message.success('保存成功!')
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['categoryForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      // console.log(file)
      // this.formData.image = file.id
      this.formData.logo_url = file.full_path
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.logo_url = null

      this.uploadFileList = fileList
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initData()
    },
    // 初始化数据
    initData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.formData = this.defaultFormData
        this.$refs['categoryForm'].resetFields()
        this.uploadFileList = []
      })
    },
    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      let ids = this.$refs['treeTable'].getExpandIds()
      let info = await this.$api.orderPlatform(orderIds)
      if (info == 'ok') {
        await this.handleQuery()
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs['treeTable'].initTableExpand(ids)
          })
        })

      }
      this.$message.success('保存成功!')
    },
    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        handle: '.drag-handle',
        setData: function(dataTransfer) {
          dataTransfer.setData('Text', '')
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        },
        onEnd: evt => {
          let oldId = evt.item.querySelectorAll('.drag-handle')[0].dataset.id
          let Ids = []
          let orderIds = []
          let newList = []
          let tmp = el.querySelectorAll('.drag-handle')
          for (let i = 0, len = tmp.length; i < len; i++) {
            newList[tmp[i].dataset.id] = { 'parent_id': tmp[i].dataset.parent_id, 'depth': tmp[i].dataset.depth }
            Ids.push(tmp[i].dataset.id)
          }
          // 处理组合实际请求后台数据
          for (let i = 0, len = Ids.length; i < len; i++) {
            if (newList[oldId].parent_id == newList[Ids[i]].parent_id && newList[oldId].depth == newList[Ids[i]].depth) {
              orderIds.push(Ids[i])
            }
          }
          this.dragSortSubmit(orderIds)
        }
      })
    },

    // 刊例配置
    showPlcPage(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/plc-set')
    }
  },
  async mounted() {
    await this.handleQuery()
    this.loadingStaus = false
    this.$nextTick(() => {
      this.dragSort()
    })
  }
}
</script>

<style lang='stylus'>
.el-button
  margin-right 4px
  margin-bottom 4px

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.col-opt {
  display flex;
  flex-direction row;
  flex-wrap wrap;
  justify-content flex-start;
  align-items center;
  gap 5px;
}
</style>
